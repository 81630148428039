


















import Vue from 'vue'

export default Vue.extend({
  name: 'Promocodes',

  props: {
    profileId: {
      type: String,
      default: ''
    }
  },

  computed: {
    componentToShow () {
      switch (Boolean(this.$route.query.isArchived)) {
        case false:
          return () => import('./Current.vue')

        case true:
          return () => import('./Archived.vue')

        default:
          return () => import('./Current.vue')
      }
    },

    offsetQuery () {
      if (!this.$route.query.offset) return null
      return { offset: this.$route.query.offset }
    }
  }
})
